//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from "vuex";
export default {
  // middleware: "authenticated",
  computed: {
    ...mapGetters(["isAuthenticated"]),
    ...mapState(["authUser"]),
    ...mapState("error", ["error"])
  },
  data() {
    return {
      showMobile: false,
      showProfile: false,
      buttonApply: false
    };
  },
  methods: {
    ...mapActions("applicationStore", ["resetDefaultState"]),
    ...mapActions(["signOut"]),
    gotoLink(link) {
      this.$router.push(link);
    },
    signingOut() {
      console.log("I am signing out for real");
      this.resetDefaultState();
      this.signOut();
    }
    // getUser() {
    //   let email = this.authUser.email;
    //   console.log(email);
    // }
  }
  // async mounted() {
  //   await this.getUser();
  //   console.log("this.email");
  //   console.log(this.email);
  // }
};
